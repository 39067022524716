export const BASE_URL = process.env.VUE_APP_API_URL || "http://localhost:3000";

// Login API
export const LOGIN = `${BASE_URL}/sign-in/`;

// Register API for Business Admin
export const REGISTER_BUSINESS_ADMIN = `${BASE_URL}/register/`;

// Activate API
export const ACTIVATE_ACCONT = activationid => `${BASE_URL}/activate/${activationid}/`;

// Forgot & Reset Password
export const FORGOT_PASSWORD = `${BASE_URL}/forgot-password/`;
export const RESET_PASSWORD = activationId => `${BASE_URL}/reset-password/${activationId}/`;

// Business API
export const BUSINESS_LIST = `${BASE_URL}/business/`;
export const BUSINESS_CREATE = `${BASE_URL}/business/`;
export const BUSINESS_UPDATE = `${BASE_URL}/business/update/`;
export const BUSINESS_RETRIEVE = businessId => `${BASE_URL}/business/${businessId}`;

// Location API
export const LOCATION_LIST = `${BASE_URL}/location/`;
export const LOCATION_CREATE = `${BASE_URL}/location/`;
export const LOCATION_UPDATE = `${BASE_URL}/location/update/`;
export const LOCATION_RETRIEVE = locationId => `${BASE_URL}/location/${locationId}`;

// Subscriber API
export const SUBSCRIBER_LIST = `${BASE_URL}/subscriber/`;
export const SUBSCRIBER_CREATE = `${BASE_URL}/subscriber/`;
export const SUBSCRIBER_UPDATE = `${BASE_URL}/subscriber/update/`;
export const SUBSCRIBER_RETRIEVE = subscriberId => `${BASE_URL}/subscriber/${subscriberId}`;
export const SUBSCRIBER_DISABLE = `${BASE_URL}/subscriber/disable/`;
export const SUBSCRIBER_LOCATIONS = `${BASE_URL}/subscriber/locations/`;

// Survey API
export const SURVEY_LIST = `${BASE_URL}/survey/`;
export const SURVEY_CREATE = `${BASE_URL}/survey/`;
export const SURVEY_RETRIEVE = surveyId => `${BASE_URL}/survey/${surveyId}/`;

// Survey Results API
export const SURVEY_RESULTS_LIST = `${BASE_URL}/survey-results/`;
export const SURVEY_RESULTS_RETRIEVE = surveyId => `${BASE_URL}/survey-result/${surveyId}`;
export const SURVEY_UPDATE = surveyId => `${BASE_URL}/update/survey-question/${surveyId}/`;
export const SURVEY_RESULTS_DETAIL = `${BASE_URL}/survey-results-detail/`;

// Global Questionnaire API
export const GLOBAL_QUESTIONNAIRE_LIST = `${BASE_URL}/global-questionnaire/`;
export const GLOBAL_QUESTIONNAIRE_CREATE = `${BASE_URL}/global-questionnaire/`;

// CDC Qustionnaire API
export const CDC_QUESTIONNAIRE = `${BASE_URL}/save/cdc-questionnaire/`;
export const LOAD_QUESTIONNAIRE_FOR_SURVEY = surveyId => `${BASE_URL}/load/cdc-questionnaire/${surveyId}/`;

// SurveyQuestion API
export const SURVEY_QUESTION_LIST = surveyId => `${BASE_URL}/survey-question/${surveyId}/`;
export const SURVEY_QUESTION_CREATE = surveyId => `${BASE_URL}/survey-question/${surveyId}/`;
export const SURVEY_QUESTION_DEACTIVATE = surveyQuestionId => `${BASE_URL}/delete/survey-question/${surveyQuestionId}/`;
export const SURVEY_QUESTION_UPDATE = surveyQuestionId => `${BASE_URL}/update/survey-question-detail/${surveyQuestionId}/`;

// User API
export const USER_LIST = `${BASE_URL}/user/`;
export const USER_UPDATE = `${BASE_URL}/user/update/`;
export const USER_CREATE_GSA = `${BASE_URL}/user/new/gsa/`;
export const USER_CREATE_BA = `${BASE_URL}/user/new/ba/`;
export const USER_CREATE_LA = `${BASE_URL}/user/new/la/`;

// Survey Answer and Result API
export const SURVEY_QUESTION_ANSWER = `${BASE_URL}/save-survey-question-answer/`;
export const SURVEY_RESULT = `${BASE_URL}/save-survey-results/`;
export const SAVE_GUEST_USER = `${BASE_URL}/save-guest-subscriber/`;
export const CHECK_USER_ID = `${BASE_URL}/check-userid/`;

// Send survey link
export const SEND_SURVEY_LINK = `${BASE_URL}/send-survey-link/`;
