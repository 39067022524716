






























































import Menu from "../components/Menu.vue";
import SendSurveyLink from '../views/Survey/SendSurveyLink.vue';

export default {
  data() {
    return {
      profile: {},
      role: null,
      surveyLinkVisible: false
    }
  },
  components: {
    Menu,
    SendSurveyLink
  },
  mounted() {
    this.loadProfile();
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onCollapse(collapsed: boolean, type: any) {
      console.log(collapsed, type);
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onBreakpoint(broken: any) {
      console.log(broken);
    },
    loadProfile() {
      this.profile = JSON.parse(localStorage.getItem('profile'));
      if (this.profile.scope === 'BA') {
        this.role = 'Business Admin';
      }
      if (this.profile.scope === 'LA') {
        this.role = 'Location Admin';
      }
      if (this.profile.scope === 'GSA') {
        this.role = 'Super Admin';
      }
    },
    surveyLinkAdded() {
      this.surveyLinkVisible = false;
    }
  },
};
