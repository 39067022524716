import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Subscriber } from './types';
import { SubscriberApi } from '../../../services';

@Module({ namespaced: true })
class SubscriberModule extends VuexModule {
  public _subscribers: Subscriber[] = [];
  public subscriber: Subscriber = null;
  public searchTerm:string = null;

  @Mutation
  public loadSubscribers(subscribers: Subscriber[]): void {
    this._subscribers = subscribers;
  }

  @Mutation
  public setSubscriber(subscriber: Subscriber): void {
    this.subscriber = subscriber;
  }

  @Mutation
  public setSearchTerm(term: string): void {
    this.searchTerm = term;
  }

  @Action
  public async load(): Promise<void> {
    const response = await SubscriberApi.list();
    const subscribers: Subscriber[] = response.data;
    this.context.commit('loadSubscribers', subscribers);
  }

  @Action
  public async reset(): Promise<void> {
    this.context.commit('loadSubscribers', []);
  }

  @Action
  public async add(subscriber: Subscriber): Promise<any> {
    const response = await SubscriberApi.create(subscriber);
    return response;
  }

  @Action
  public async retrieve(subscriberId: string): Promise<void> {
    const response = await SubscriberApi.retrieve(subscriberId);
    if (response.data.length > 0) {
        this.context.commit('setSubscriber', response.data[0]);
    }
    return;
  }

  @Action
  public async search(term:string): Promise<void> {
    this.context.commit('setSearchTerm', term);
  }

  @Action
  public async disableSubscriber(subscriberId:string): Promise<void> {
    await SubscriberApi.disableSubscriber({subscriberId});
  }

  get subscribers(): Subscriber[] {
    let results = this._subscribers;
    if (this.searchTerm) {
      results = results.filter(record => {
        if (
          (record.firstName ? record.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.lastName ? record.lastName.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.email ? record.email.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.state ? record.state.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.gender ? record.gender.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.contactNumber ? record.contactNumber.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.screen2passId ? record.screen2passId.toLowerCase().includes(this.searchTerm.toLowerCase()) : false)
        ) return true;
        return false;
      })
    }
    return results;
  }

}
export default SubscriberModule;