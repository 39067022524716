


























import Logo from "../components/Logo.vue";

export default {
  components: {
    Logo,
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onCollapse(collapsed: boolean, type: any) {
      console.log(collapsed, type);
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onBreakpoint(broken: any) {
      console.log(broken);
    },
  },
};
