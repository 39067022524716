import axios from "axios";

import { Location } from "@/store/modules/location/types";
import { Subscriber } from "@/store/modules/subscriber/types";
import { Survey } from "@/store/modules/survey/types";
import * as config from "../config";
import { Question } from "@/store/modules/questionnaire/types";
import { User } from "@/store/modules/users/types";
import { Business } from "@/store/modules/business/types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class BusinessApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async list() {
    return axios.get(config.BUSINESS_LIST, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async create(business: Business) {
    return axios.post(config.BUSINESS_CREATE, business, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async update(payload) {
    return axios.post(config.BUSINESS_UPDATE, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class LocationApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async list() {
    return axios.get(config.LOCATION_LIST, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async create(location: Location) {
    return axios.post(config.LOCATION_CREATE, location, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async update(payload) {
    return axios.post(config.LOCATION_UPDATE, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SubscriberApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async list() {
    return axios.get(config.SUBSCRIBER_LIST, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async locations(subscriberId:string) {
    return axios.get(config.SUBSCRIBER_LOCATIONS, {
      params: {
        subscriberId
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async retrieve(subscriberId) {
    return axios.get(config.SUBSCRIBER_RETRIEVE(subscriberId));
  }

  static async create(subscriber: Subscriber) {
    return axios.post(config.SUBSCRIBER_CREATE, subscriber, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async saveGuestSubscriber(payload) {
    return axios.post(config.SAVE_GUEST_USER, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async checkIfUserIdExists(payload) {
    return axios.post(config.CHECK_USER_ID, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async disableSubscriber(payload) {
    return axios.post(config.SUBSCRIBER_DISABLE, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async update(payload) {
    return axios.post(config.SUBSCRIBER_UPDATE, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SurveyApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async list() {
    return axios.get(config.SURVEY_LIST, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async retrieve(surveyId) {
    return axios.get(config.SURVEY_RETRIEVE(surveyId), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async create(survey: Survey) {
    return axios.post(config.SURVEY_CREATE, survey, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async updateText(surveyId, payload) {
    return axios.post(config.SURVEY_UPDATE(surveyId), payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async sendSurveyLink(payload) {
    return axios.post(config.SEND_SURVEY_LINK, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class QuestionApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async list() {
    return axios.get(config.GLOBAL_QUESTIONNAIRE_LIST, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async create(question: Question) {
    return axios.post(config.GLOBAL_QUESTIONNAIRE_CREATE, question, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async saveCDCQuestionnaire() {
    return axios.post(config.CDC_QUESTIONNAIRE, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async loadCDCQuestionnaireForSurvey(surveyId: string) {
    return axios.post(config.LOAD_QUESTIONNAIRE_FOR_SURVEY(surveyId), null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SurveyQuestionApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async list(surveyId: string) {
    return axios.get(config.SURVEY_QUESTION_LIST(surveyId), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async create(surveyId: string, question: Question) {
    question.surveyId = surveyId;
    return axios.post(config.SURVEY_QUESTION_CREATE(surveyId), question, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async saveAnswer(payload) {
    return axios.post(config.SURVEY_QUESTION_ANSWER, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async delete(surveyQuestionId) {
    return axios.post(config.SURVEY_QUESTION_DEACTIVATE(surveyQuestionId), {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async update(surveyQuestionId, payload) {
    return axios.post(config.SURVEY_QUESTION_UPDATE(surveyQuestionId), payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

}

class SurveyResultApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async list() {
    return axios.get(config.SURVEY_RESULTS_LIST, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async retrieve(surveyId) {
    return axios.get(config.SURVEY_RESULTS_RETRIEVE(surveyId));
  }

  static async create(payload) {
    return axios.post(config.SURVEY_RESULT, payload);
  }

  static async details(surveyId, subscriberId, attempt) {
    return axios.get(config.SURVEY_RESULTS_DETAIL, {
      params: {
        surveyId,
        subscriberId,
        attempt
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }
}

class UserApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async list() {
    return axios.get(config.USER_LIST, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async createGSA(user: User) {
    return axios.post(config.USER_CREATE_GSA, user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async createBA(user: User) {
    return axios.post(config.USER_CREATE_BA, user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async createLA(user: User) {
    return axios.post(config.USER_CREATE_LA, user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

  static async update(user) {
    return axios.post(config.USER_UPDATE, user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
  }

}

class AuthApi {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async signin(payload) {
    return axios.post(config.LOGIN, payload);
  }

  static async register(payload) {
    return axios.post(config.REGISTER_BUSINESS_ADMIN, payload);
  }

  static async activate(activationid) {
    return axios.get(config.ACTIVATE_ACCONT(activationid));
  }

  static isLoggedIn() {
    const token = localStorage.getItem('token');
    if (!token) return false;
    return true;
  }

  static logout() {
    localStorage.clear();
  }

  static async forgotPassword(email:string) {
    return axios.post(config.FORGOT_PASSWORD, {email});
  }

  static async resetPassword(activationId:string, password:string) {
    return axios.post(config.RESET_PASSWORD(activationId), {password});
  }
}

const sortStringAttributes = (a, b) => {
  try {
    a = a.toUpperCase(); // ignore upper and lowercase
    b = b.toUpperCase(); // ignore upper and lowercase
  } catch (err) {
    console.log(err);
  }
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export {
  BusinessApi,
  LocationApi,
  SubscriberApi,
  SurveyApi,
  SurveyResultApi,
  AuthApi,
  sortStringAttributes,
  QuestionApi,
  UserApi,
  SurveyQuestionApi
};
