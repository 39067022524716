import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { SurveyResult } from './types';
import { SurveyResultApi } from '../../../services';

@Module({ namespaced: true })
class SurveyResultModule extends VuexModule {
  public _surveyResults: SurveyResult[] = [];
  public searchTerm:string = null;

  @Mutation
  public loadSurveyResults(surveyResults: SurveyResult[]): void {
    this._surveyResults = surveyResults;
  }

  @Mutation
  public setSearchTerm(term: string): void {
    this.searchTerm = term;
  }

  @Action
  public async load(): Promise<void> {
    const response = await SurveyResultApi.list();
    const surveyResults: SurveyResult[] = response.data;
    this.context.commit('loadSurveyResults', surveyResults);
  }
  
  @Action
  public async reset(): Promise<void> {
    this.context.commit('loadSurveyResults', []);
  }

  @Action
  public async retrieve(surveyId: string): Promise<void> {
    const response = await SurveyResultApi.retrieve(surveyId);
    if (response.data.length > 0) {
        this.context.commit('setSurveyResult', response.data[0]);
    }
    return;
  }

  @Action
  public async search(term:string): Promise<void> {
    this.context.commit('setSearchTerm', term);
  }

  get surveyResults(): SurveyResult[] {
    let results = this._surveyResults;
    if (this.searchTerm) {
      results = results.filter(record => {
        if (
          (record.firstName ? record.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.lastName ? record.lastName.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.email ? record.email.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.contactNumber ? record.contactNumber.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.gender ? record.gender.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.dob ? record.dob.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.screen2passId ? record.screen2passId.toLowerCase().includes(this.searchTerm.toLowerCase()) : false)
        ) return true;
        return false;
      })
    }
    return results;
  }
}
export default SurveyResultModule;