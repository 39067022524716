import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
 
Vue.use(VueFilterDateFormat);
Vue.use(wysiwyg, {});
Vue.config.productionTip = false;
Vue.use(Antd);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
