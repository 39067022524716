<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-form-item v-if="scope !== 'LA'">
        <a-select
          :disabled="whileSaving"
          v-decorator="[
            'locationId',
            {
              rules: [{ required: true, message: 'Please input Location!' }],
            },
          ]"
          size="large"
          placeholder="Location*"
        >
          <a-select-option
            :value="item.locationId"
            v-for="item in getLocations()"
            v-bind:key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="scope === 'LA'" style="display:none;">
        <a-select
          :disabled="whileSaving"
          v-decorator="[
            'surveyId',
            {
              rules: [{ required: true, message: 'Please Select Questionnaire!' }],
            },
          ]"
          size="large"
          placeholder="Questionnaire*"
        >
          <a-select-option
            :value="item.surveyId"
            v-for="item in surveys"
            v-bind:key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          :disabled="whileSaving"
          v-decorator="[
            'toEmails',
            {
              rules: [{ required: true, message: 'Please input Emails!' }],
            },
          ]"
          size="large"
          mode="tags"
          style="width: 100%"
          placeholder="Add emails"
        >
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button
          size="large"
          type="primary"
          html-type="submit"
          class="login-form-button"
          style="width: 100%; background: #5168ea; border-color: #5168ea"
        >
          <a-icon type="lock" style="color: white" />
          <span v-if="!whileSaving">Send Survey Link</span>
          <span v-if="whileSaving">Sending...</span>
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { SurveyApi } from "../../services";

export default {
  props: {
    businesses: Array,
    locations: Array,
    scope: String,
    survey: Object
  },
  data() {
    return {
      whileSaving: false,
      surveys: [],
      profile: JSON.parse(localStorage.getItem("profile") || "{}"),
    };
  },
  async beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  async mounted() {
    if (this.profile.scope === 'LA') {
        await this.getSurveys();
    }
  },
  methods: {
    async getSurveys() {
        const response = await SurveyApi.list();
        this.surveys = response.data;
        this.form.setFieldsValue({
          surveyId: response.data[0].surveyId
        });
    },
    getLocations() {
      const businessId = this.businessId;
      if (!businessId) {
        return this.locations;
      }
      return this.locations.filter(
        (record) => record.businessId === businessId
      );
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (values.toEmails.length > 1) {
            this.$message.error("Only one email is allowed to send at a time.");
            console.log(err);
            this.whileSaving = false;
            return;
          }
          try {
            this.whileSaving = true;
            if (this.scope === 'LA') {
                values.locationId = this.profile.locationId;
            }
            if (this.scope !== 'LA') {
                values.surveyId = this.survey.surveyId;
            }
            await SurveyApi.sendSurveyLink(values);
            this.$message.success("Successfully sent");
            this.$emit("success", true);
            this.whileSaving = false;
            console.log("Received values of form: ", values);
          } catch (err) {
            this.$message.error("Failed to send. Please try again later");
            console.log(err);
            this.whileSaving = false;
          }
        }
      });
    },
  },
};
</script>
<style scoped>
a {
  color: #5168ea !important;
}
.auth-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: -10px;
  display: inline-block;
  margin-top: -8px;
}
</style>