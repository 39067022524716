import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Business } from './types';
import { BusinessApi } from '../../../services';

@Module({ namespaced: true })
class BusinessModule extends VuexModule {
  public _businesses: Business[] = [];
  public business: Business = null;
  public searchTerm:string = null;

  @Mutation
  public loadBusinesss(businesss: Business[]): void {
    this._businesses = businesss;
  }

  @Mutation
  public setBusiness(business: Business): void {
    this.business = business;
  }

  @Mutation
  public setSearchTerm(term: string): void {
    this.searchTerm = term;
  }

  @Action
  public async load(): Promise<void> {
    const response = await BusinessApi.list();
    const businesss: Business[] = response.data;
    this.context.commit('loadBusinesss', businesss);
  }

  @Action
  public async reset(): Promise<void> {
    this.context.commit('loadBusinesss', []);
  }

  @Action
  public async loadBusinesses(): Promise<void> {
    const response = await BusinessApi.list();
    const businesss: Business[] = response.data;
    this.context.commit('loadBusinesss', businesss);
  }

  @Action
  public async add(business: Business): Promise<any> {
    const response = await BusinessApi.create(business);
    return response;
  }

  @Action
  public async search(term:string): Promise<void> {
    this.context.commit('setSearchTerm', term);
  }

  get businesses(): Business[] {
    let results = this._businesses
    if (this.searchTerm) results = results.filter(record => record.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    return results;
  }
}
export default BusinessModule;