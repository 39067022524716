import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Location } from './types';
import { LocationApi } from '../../../services';

@Module({ namespaced: true })
class LocationModule extends VuexModule {
  public _locations: Location[] = [];
  public location: Location = null;
  public searchTerm:string = null;

  @Mutation
  public loadLocations(locations: Location[]): void {
    this._locations = locations;
  }

  @Mutation
  public setLocation(location: Location): void {
    this.location = location;
  }

  @Mutation
  public setSearchTerm(term: string): void {
    this.searchTerm = term;
  }

  @Action
  public async load(): Promise<void> {
    const response = await LocationApi.list();
    const locations: Location[] = response.data;
    this.context.commit('loadLocations', locations);
  }

  @Action
  public async reset(): Promise<void> {
    this.context.commit('loadLocations', []);
  }

  @Action
  public async loadAllLocations(): Promise<void> {
    const response = await LocationApi.list();
    const locations: Location[] = response.data;
    this.context.commit('loadLocations', locations);
  }

  @Action
  public async add(location: Location): Promise<any> {
    const response = await LocationApi.create(location);
    return response;
  }

  @Action
  public async search(term:string): Promise<void> {
    this.context.commit('setSearchTerm', term);
  }

  get locations(): Location[] {
    let results = this._locations
    if (this.searchTerm) results = results.filter(record => record.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    return results;
  }
}
export default LocationModule;