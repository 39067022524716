import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Question } from './types';
import { SurveyQuestionApi } from '../../../services';

@Module({ namespaced: true })
class SurveyQuestionModule extends VuexModule {
  public questions: Question[] = [];
  public question: Question = null;

  @Mutation
  public loadQuestions(questions: Question[]): void {
    this.questions = questions;
  }

  @Mutation
  public setQuestion(question: Question): void {
    this.question = question;
  }

  @Action
  public async load(surveyId:string): Promise<void> {
    const response = await SurveyQuestionApi.list(surveyId);
    const questions: Question[] = response.data;
    this.context.commit('loadQuestions', questions);
  }

  @Action
  public async reset(surveyId:string): Promise<void> {
    this.context.commit('loadQuestions', []);
  }
}
export default SurveyQuestionModule;