import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Question } from './types';
import { QuestionApi } from '../../../services';

@Module({ namespaced: true })
class QuestionnaireModule extends VuexModule {
  public _questions: Question[] = [];
  public question: Question = null;
  public searchTerm:string = null;

  @Mutation
  public loadQuestions(questions: Question[]): void {
    this._questions = questions;
  }

  @Mutation
  public setQuestion(question: Question): void {
    this.question = question;
  }

  @Mutation
  public setSearchTerm(term: string): void {
    this.searchTerm = term;
  }

  @Action
  public async load(): Promise<void> {
    const response = await QuestionApi.list();
    const questions: Question[] = response.data;
    this.context.commit('loadQuestions', questions);
  }

  @Action
  public async reset(): Promise<void> {
    this.context.commit('loadQuestions', []);
  }

  @Action
  public async add(question: Question): Promise<any> {
    const response = await QuestionApi.create(question);
    return response;
  }

  @Action
  public async loadCDCQuestions(): Promise<void> {
    await QuestionApi.saveCDCQuestionnaire();
    return;
  }

  @Action
  public async search(term:string): Promise<void> {
    this.context.commit('setSearchTerm', term);
  }

  get questions(): Question[] {
    let results = this._questions;
    if (this.searchTerm) {
      results = results.filter(record => {
        if (
          (record.name ? record.name.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.title ? record.title.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.title ? record.title.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.status ? record.status.toLowerCase().includes(this.searchTerm.toLowerCase()) : false)
        ) return true;
        return false;
      })
    }
    return results;
  }

}
export default QuestionnaireModule;