import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { User } from './types';
import { UserApi } from '../../../services';

@Module({ namespaced: true })
class UserModule extends VuexModule {
  public _users: User[] = [];
  public searchTerm:string = null;

  @Mutation
  public loadUsers(users: User[]): void {
    this._users = users;
  }

  @Action
  public async load(): Promise<void> {
    const response = await UserApi.list();
    const users: User[] = response.data;
    this.context.commit('loadUsers', users);
  }

  @Action
  public async reset(): Promise<void> {
    this.context.commit('loadUsers', []);
  }

  @Mutation
  public setSearchTerm(term: string): void {
    this.searchTerm = term;
  }

  @Action
  public async search(term:string): Promise<void> {
    this.context.commit('setSearchTerm', term);
  }

  get users(): User[] {
    console.log(this.searchTerm);
    let results = this._users;
    if (this.searchTerm) {
      results = results.filter(record => {
        if (
          (record.firstName ? record.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.lastName ? record.lastName.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.contactNumber ? record.contactNumber.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.username ? record.username.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.email ? record.email.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.type ? record.type.toLowerCase().includes(this.searchTerm.toLowerCase()) : false)
        ) return true;
        return false;
      })
    }
    return results;
  }
}
export default UserModule;