import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Survey } from './types';
import { SurveyApi } from '../../../services';

@Module({ namespaced: true })
class SurveyModule extends VuexModule {
  public _surveys: Survey[] = [];
  public survey: Survey = null;
  public searchTerm:string = null;

  @Mutation
  public loadSurveys(surveys: Survey[]): void {
    this._surveys = surveys;
  }

  @Mutation
  public setSurvey(survey: Survey): void {
    this.survey = survey;
  }

  @Mutation
  public setSearchTerm(term: string): void {
    this.searchTerm = term;
  }

  @Action
  public async load(): Promise<void> {
    const response = await SurveyApi.list();
    const surveys: Survey[] = response.data;
    this.context.commit('loadSurveys', surveys);
  }

  @Action
  public async reset(): Promise<void> {
    this.context.commit('loadSurveys', []);
  }

  @Action
  public async add(survey: Survey): Promise<any> {
    const response = await SurveyApi.create(survey);
    return response;
  }

  @Action
  public async retrieve(surveyId: string): Promise<void> {
    const response = await SurveyApi.retrieve(surveyId);
    if (response.data.length > 0) {
        this.context.commit('setSurvey', response.data[0]);
    }
    return;
  }

  @Action
  public async search(term:string): Promise<void> {
    this.context.commit('setSearchTerm', term);
  }

  get surveys(): Survey[] {
    let results = this._surveys;
    if (this.searchTerm) {
      results = results.filter(record => {
        if (
          (record.name ? record.name.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.description ? record.description.toLowerCase().includes(this.searchTerm.toLowerCase()) : false) ||
          (record.status ? record.status.toLowerCase().includes(this.searchTerm.toLowerCase()) : false)
        ) return true;
        return false;
      })
    }
    return results;
  }

}
export default SurveyModule;