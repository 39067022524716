
















































import { Component, Vue } from "vue-property-decorator";
import Icon from "./Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class Menu extends Vue {
  role:string
  profile = {
    scope: null
  }
  hasPermissions = {
    locations: false,
    knownUsers: false,
    questionnaire: false,
    surveyResults: false
  }

  mounted() {
    this.loadProfile();
  }

  get selected() {
    return this.$route.meta.menu.toLowerCase()
  }
  isSelected(name) {
    return this.$route.meta.menu.toLowerCase() === name.toLowerCase();
  }
  logout(e) {
    e.preventDefault();
    localStorage.clear();
    this.$router.push('/auth')
  }
  loadProfile() {
      this.profile = JSON.parse(localStorage.getItem('profile'));
      if (this.profile.scope === 'BA') {
        this.role = 'Business Admin';
        this.hasPermissions.locations = true;
        this.hasPermissions.knownUsers = true;
        this.hasPermissions.questionnaire = true;
        this.hasPermissions.surveyResults = true;
      }
      if (this.profile.scope === 'LA') {
        this.role = 'Location Admin';
        this.hasPermissions.locations = false;
        this.hasPermissions.knownUsers = true;
        this.hasPermissions.questionnaire = false;
        this.hasPermissions.surveyResults = true;
      }
      if (this.profile.scope === 'GSA') {
        this.role = 'Super Admin';
        this.hasPermissions.locations = true;
        this.hasPermissions.knownUsers = true;
        this.hasPermissions.questionnaire = true;
        this.hasPermissions.surveyResults = true;
      }



    }
}
