import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Base from "../layout/Base.vue";
import Auth from "../layout/Auth.vue";
import Survey from "../layout/Survey.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Features",
    component: () =>
        import(/* webpackChunkName: "manage_nfc" */ "../views/Feature/Feature.vue"),
  },
  {
    path: "",
    name: "Base",
    component: Base,
    children: [
      {
        path: "/locations",
        name: "Location",
        meta: {
          menu: "Location",
          requiresAuth: true,
          GSA: true,
          BA: true,
          LA: false
        },
        component: () =>
          import(/* webpackChunkName: "location" */ "../views/Location/LocationList.vue"),
      },
      {
        path: "/known-users",
        name: "KnownUsers",
        meta: {
          menu: "KnownUsers",
          requiresAuth: true,
          GSA: true,
          BA: true,
          LA: true
        },
        component: () =>
          import(/* webpackChunkName: "knownuser" */ "../views/Subscriber/SubscriberList.vue"),
      },
      {
        path: "/survey",
        name: "Survey",
        meta: {
          menu: "Survey",
          requiresAuth: true,
          GSA: true,
          BA: true,
          LA: false
        },
        component: () =>
          import(/* webpackChunkName: "survey" */ "../views/Survey/SurveyList.vue"),
      },
      {
        path: "/survey/:id/configure",
        name: "SurveyConfigure",
        meta: {
          menu: "Survey",
          requiresAuth: true,
          GSA: true,
          BA: true,
          LA: false
        },
        component: () =>
          import(/* webpackChunkName: "survey" */ "../views/Survey/SurveyConfigure.vue"),
      },
      {
        path: "/survey-results",
        name: "SurveyResults",
        meta: {
          menu: "SurveyResults",
          requiresAuth: true,
          GSA: true,
          BA: true,
          LA: true
        },
        component: () =>
          import(/* webpackChunkName: "surveyresult" */ "../views/Survey/SurveyResult.vue"),
      },
      {
        path: "/global-questionnaire",
        name: "GlobalQuestionnaire",
        meta: {
          menu: "GlobalQuestionnaire",
          requiresAuth: true,
          GSA: true,
          BA: false,
          LA: false
        },
        component: () =>
          import(/* webpackChunkName: "gloalquestionnaire" */ "../views/Survey/GlobalQuestionnaireList.vue"),
      },
      {
        path: "/manage-businesses",
        name: "ManageBusinesses",
        meta: {
          menu: "ManageBusinesses",
          requiresAuth: true,
          GSA: true,
          BA: false,
          LA: false
        },
        component: () =>
          import(/* webpackChunkName: "managebusiness" */ "../views/Business/BusinessList.vue"),
      },
      {
        path: "/manage-users",
        name: "ManageUsers",
        meta: {
          menu: "ManageUsers",
          requiresAuth: true,
          GSA: true,
          BA: false,
          LA: false
        },
        component: () =>
          import(/* webpackChunkName: "manageusers" */ "../views/Users/UserList.vue"),
      },
      {
        path: "/profile",
        name: "Profile",
        meta: {
          menu: "Profile",
          requiresAuth: true,
          GSA: true,
          BA: true,
          LA: true
        },
        component: () =>
          import(/* webpackChunkName: "manage_nfc" */ "../views/Profile.vue"),
      },
      {
        path: "/settings",
        name: "Settings",
        meta: {
          menu: "Settings",
          requiresAuth: true,
          GSA: true,
          BA: true,
          LA: true
        },
        component: () =>
          import(/* webpackChunkName: "manage_nfc" */ "../views/Settings.vue"),
      },
    ],
  },
  {
    path: "/auth",
    name: "AuthBase",
    component: Auth,
    children: [
      {
        path: "",
        name: "AuthBase",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/Auth/SignIn.vue"),
      },
      {
        path: "sign-in",
        name: "AuthBase",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/Auth/SignIn.vue"),
      },
      {
        path: "register",
        name: "AuthBase",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/Auth/Register.vue"),
      },
      {
        path: "forgot-password",
        name: "AuthBase",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/Auth/ForgotPassword.vue"),
      },
      {
        path: "reset-password/:activationid",
        name: "AuthBase",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/Auth/ResetPassword.vue"),
      },
      {
        path: "activate/:token",
        name: "AuthBase",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/Auth/Activate.vue"),
      }
    ],
  },
  {
    path: "/take-survey",
    name: "SurveyBase",
    component: Survey,
    children: [
      {
        path: "location/:locationid/start/:surveyid",
        name: "StartSurvey",
        meta: {
          menu: "SurveyBase",
          requiresAuth: false
        },
        component: () =>
          import(/* webpackChunkName: "takesurvey" */ "../views/TakeSurvey/StartSurvey.vue"),
      },
      {
        path: "location/:locationid/view/:surveyid",
        name: "ViewSurvey",
        meta: {
          menu: "SurveyBase",
          requiresAuth: false
        },
        component: () =>
          import(/* webpackChunkName: "takesurvey" */ "../views/TakeSurvey/ViewSurvey.vue"),
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next({
        path: '/auth',
        params: { nextUrl: to.fullPath }
      })
    } else {
      const profile = JSON.parse(localStorage.getItem('profile'))
      switch(profile.scope) {
        case "GSA":
          if (to.matched.some(record => record.meta.GSA)) {
            next()
          } else {
            next({name: 'Location'})
          }
          break;
        case "BA":
          if (to.matched.some(record => record.meta.BA)) {
            next()
          } else {
            next({name: 'Location'})
          }
          break;
        case "LA":
          if (to.matched.some(record => record.meta.LA)) {
            next()
          } else {
            next({name: 'KnownUsers'})
          }
          break;
        default:
          break;
      }
    }
  } else {
    next()
  }
})

export default router;
