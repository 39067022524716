import Vue from "vue";
import Vuex from "vuex";

import BusinessModule from "./modules/business";
import LocationModule from "./modules/location";
import SubscriberModule from "./modules/subscriber";
import SurveyModule from "./modules/survey";
import SurveyResultModule from "./modules/surveyresults";
import QuestionnaireModule from "./modules/questionnaire";
import SurveyQuestionModule from "./modules/surveyquestion";
import UserModule from "./modules/users";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    BusinessModule,
    LocationModule,
    SubscriberModule,
    SurveyModule,
    SurveyResultModule,
    QuestionnaireModule,
    UserModule,
    SurveyQuestionModule
  }
});